import { lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';


export const IndexPage = lazy(() => import('../pages/app'));
export const Page404 = lazy(() => import('../pages/page-not-found'));
export const PrivacyPolicyPage = lazy(() => import('../pages/privacy-policy'));
export const TimelinePage = lazy(() => import('../pages/timeline'));
export const DisclaimerPage = lazy(() => import('../pages/disclaimer'));
export const LegalNoticePage = lazy(() => import('../pages/legalNotice'));
export const CoversPage = lazy(() => import('../pages/covers'));
export const TermsAndConditionsPage = lazy(() => import('../pages/terms-and-conditions'));
// export const WorldContactPage = lazy(() => import('../pages/world-contact'));
export const ProductsPage = lazy(() => import('../pages/products'));
export const RepairPage = lazy(() => import('../pages/repairs'));
export const RedirectPage = lazy(() => import('../pages/redirect'));
const ConfigRoute = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.hash.toLowerCase();
    if (path === '#contact' || path === '#Contact') {
      navigate('/');
    } 
  }, [navigate]);  

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/hardware-repair" element={<TimelinePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      {/* <Route path="/world-contact" element={<WorldContactPage />} /> */}
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/repairs-rates" element={<RepairPage />} />
      <Route path="/legal-notice" element={<LegalNoticePage />} />
      <Route path="/covers/:coverID" element={<CoversPage />} />
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/en-en" element={<IndexPage />} />
      <Route path="/en-en/" element={<IndexPage />} />
      <Route path="/ca-ca" element={<IndexPage />} />
      <Route path="/ca-ca/" element={<IndexPage />} />
      <Route path="/world-contact-fr" element={<Navigate to="/fr-fr" replace />} />
      <Route path="/world-contact-fr/" element={<Navigate to="/fr-fr" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />

    </Routes>
  );
};

export default ConfigRoute;
