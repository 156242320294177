import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_en from './translations/en.json';
/*import global_fr from './translations/fr.json';
import global_it from './translations/it.json';
import global_es from './translations/es.json';
import global_de from './translations/de.json';*/

// Get the saved language from localStorage or default to 'en'localStorage.getItem('i18nextLng')
const savedLanguage =  'en';

i18next.init({
  interpolation: { escapeValue: false },
  lng: savedLanguage,
  resources: {
    en: { global: global_en },
   /* ca: { global: global_en },
    fr: { global: global_fr },
    it: { global: global_it },
    es: { global: global_es },
    de: { global: global_de },*/
  },
});

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
